<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->

    <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <b-form-select v-model="siteData">
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                      <b-form-select v-model="supplierData">
                          <b-form-select-option value="" disabled>Select Supplier</b-form-select-option>
                          <b-form-select-option :value="supplier._id" v-for="supplier in suppliers" :key="supplier._id">{{supplier.name | capitalize}}</b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five" v-if="email_status == 'sent'">
                      <div class="d-flex align-items-center">
                        <!-- <flat-pickr
                          v-model="date_range"
                          :config="date_config"
                          class="form-control flat-picker"
                          placeholder="Select Date Range"
                          @on-change="setDate()"
                        /> -->
                        <datepicker v-model="month_year" :format="customFormatterMonthCompleteYear" minimum-view="month" maximum-view="year" placeholder="Select Month & Year" class="custom-datepicker" @input="setMonthYear"></datepicker> 
                      </div>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>
    
        <b-card no-body class="mb-0" v-if="siteData != null">
      
      <!-- tab component starts -->

      <b-tabs pills justified class="margin_top_zero_mobile tab_ul button-padding table_button_tab">                
            
            <b-tab title="Pending" lazy @click="email_status = 'pending'; start = ''; end = ''; date_range = ''; month_year = ''; month = ''; year = ''" :active="email_status == 'pending'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Pending</span>

                  <b-badge variant="warning" style="margin-left: 5px;" v-if="pending > 0">
                      <span>{{ pending }}</span>
                  </b-badge>

                </div>
              </template>
            </b-tab>
            
            <b-tab title="Completed" lazy @click="email_status = 'sent'" :active="email_status == 'sent'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Sent</span>
                </div>
              </template>
            </b-tab>

            <b-tab title="All" lazy @click="email_status = 'failed'; start = ''; end = ''; date_range = ''; month_year = ''; month = ''; year = ''"  :active="email_status == 'failed'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Failed</span>
                </div>
              </template>
            </b-tab>

            <InventoryPurchaseOrderComponent :siteData="siteData" :supplierData="supplierData" :email_status="email_status" :start="start" :end="end" :month="month" :year="year"/>

        </b-tabs>

      <!-- tab component ends -->

      
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormGroup, BTabs, BTab,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, BAlert, VBTooltip, BFormCheckbox,BForm, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import InventoryPurchaseOrderComponent from './InventoryPurchaseOrderComponent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from "moment";
import Bus from "../../../event-bus";
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    Datepicker,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormSelect, 
    BFormSelectOption,
    InventoryPurchaseOrderComponent,
    BTabs, BTab, flatPickr
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
        pending:0,

        sites:[],
        siteData:'',
        suppliers:[],
        supplierData:'',
        email_status:'pending',
        
        error_message:null,
        popshowDismissibleAlert:false,

        start: ''/* moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') */,
        end: ''/* moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY') */,
        date_config: {
          mode: 'range',
          dateFormat: 'd M Y',
          defaultDate: [
            // moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),
            // moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY')
          ]
        },
        date_range: ''/* moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') + ' to ' + moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY') */,
        month_year: '',
        month: '',
        year: ''
    }
  },
  methods : {
    customFormatterMonthCompleteYear(date) {
        return moment(date).format('MMMM YYYY');
    },

    setMonthYear(){
      this.month = moment(new Date(this.month_year)).startOf('month').format('MMMM');
      this.year = moment(new Date(this.month_year)).endOf('month').format('YYYY');
    },

    setDate(){
        var date = this.date_range.split(' to ');

        if (date[1]) {
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
        }else{
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
        }
		},

    allSites(){
      return this.$store.dispatch(POST_API, {
          data:{
          role:this.$store.getters.currentUser.role,
          om_sites:this.$store.getters.currentUser.om_sites,
          },
          api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              var obj = {
                  _id:'all-site',
                  site_name:'All Project Sites',
                  pdf_date_format: 'DD MMM YYYY',
                  pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);
              
              this.siteData = this.defaultSite(this.sites)._id;

              return this.sites;
          }
      });
    },
    allSuppliers(){
      return this.$store.dispatch(POST_API, {
          data:{
          },
          api: '/api/all-suppliers'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.suppliers = this.$store.getters.getResults.data;
              var obj = {
                  _id:'',
                  name:'All Suppliers',
              }
              this.suppliers.unshift(obj);
              return this.suppliers;
          }
      });
    },
    
    breadCrumb(){
      var item = [{
        to: { name: 'client-dashboard' },
        text: 'Dashboard',
      }, {
        to: null,
        text: 'Inventory'
      }, {
        to: null,
        text: 'Reports'
      }, {
        to: null,
        text: 'Purchase Order',
        active: true
      }];;
      return item;
    },
    
  },
  mounted(){
    this.allSites();
    this.allSuppliers();

    Bus.$on('updateCountPO', (data) => {
      // console.log('data', data);
      this.pending = data;
    })

  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.border-danger {
  border: 2px solid #ea5455 !important;
  padding: 2px !important;
}
.border-secondary {
  border: 2px solid rgb(221, 221, 221) !important;
  padding: 2px !important;
}
.card-body{
  padding-top: 1rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
